<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class=" flex-column  bg-white overflow-hidden radius-20">
				<div class="bg-popup-title pa-10-20 flex-row justify-space-between items-center under-line">
					<h4>출금 요청</h4>
					<v-icon
						@click="cancel"
					>mdi-close-circle</v-icon>
				</div>

				<div
					class=" overflow-y-auto pa-10 "
					style="max-height: 500px"
				>
					<template
						v-if="is_holder"
					>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2 ">
								<select
									v-model="item_withdrawal.bank_code"
									class=""
									disabled=""
									style="border: unset; color: black;"
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2">{{ item_withdrawal.bank_account }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2" :class="{ 'color-red': !item_holder.realBankOwnerName }">{{ item_holder.realBankOwnerName ? item_holder.realBankOwnerName : '출금계좌를 다시 확인하세요' }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2">{{ real_amount | makeComma }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2">{{ item_withdrawal.depositor }}</div>
						</div>
					</template>

					<template
						v-else
					>
						<div class="flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금가능금액</div>
							<div class="flex-2"><input class="input-box  size-px-14 font-weight-bold" :placeholder="withdrawal_balance | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 color-red text-right size-px-9">
							출금가능금액은 출금수수료 {{ total_fee | makeComma}}원을 차감한 금액입니다.
						</div>
						<div
							v-if="items_account.length > 0"
							class="mt-10 flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold">최근출금계좌</div>
							<div class="flex-2">
								<select
									v-model="account_uid"
									class="input-box "
									@change="setAccount"
								>
									<option value="">선택하세요</option>
									<option
										v-for="(item, a_index) in list_account"
										:key="'account_' + a_index"
										:value="item.uid"
									>{{ item.bank_name }} {{ item.bank_holder }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">은행</div>
							<div class="flex-2">
								<select
									v-model="item_withdrawal.bank_code"
									class="input-box "
								>
									<option value="">은행을 선택하세요</option>
									<option
										v-for="(item, b_index) in user.bank_list"
										:key="'bank_' + b_index"
										:value="item.bankId"
									>{{ item.bankName }}</option>
								</select>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 계좌</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_account" class="input-box " placeholder="출금 계좌를 입력하세요" maxlength="50" type="number" :rules="$rules.max(item_withdrawal, 'bank_account', 50)" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">예금주</div>
							<div class="flex-2"><input v-model="item_withdrawal.bank_holder" class="input-box " placeholder="예금주를 입력하세요" maxlength="50" /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">1회 출금한도</div>
							<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.payment_limit | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">최소 출금액</div>
							<div class="flex-2 "><input class="input-box  size-px-14 font-weight-bold" :placeholder="shop_info.minimum | makeComma"  disabled /></div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">출금 금액</div>
							<div class="flex-2 box">
								<div class="pa-10 justify-space-between ">
									<input v-model="item_withdrawal.amount" class=" " placeholder="금액을 입력하세요" maxlength="5" :rules="[$rules.max(item_withdrawal, 'amount', 9)]" type="number" />
									<div style="width: 40px"></div>
								</div>
							</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold"></div>
							<div class="flex-2 right color-blue justify-space-between">{{ txt_amount }}원</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class="flex-1 font-weight-bold">입금자명</div>
							<div class="flex-2"><input v-model="item_withdrawal.depositor" class="input-box " placeholder="입금자명을 입력하세요" maxlength="10" /></div>
						</div>
						<div
							v-if="is_holder"
							class="mt-10 flex-row justify-space-between items-center"
						>
							<div class="flex-1 font-weight-bold color-red">예금주 확인</div>
							<div class="flex-2 "><input class="input-box text-right" placeholder="입금자명을 입력하세요" maxlength="10" disabled :value="item_holder.realBankOwnerName" /></div>
						</div>
					</template>
				</div>

				<div
					v-if="is_withdrawal"
					class="mt-auto "
				>
					<button
						v-if="is_holder"
						@click="onPin"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_confirm"
					><slot name="name-confirm">확인</slot></button>
					<button
						v-else
						@click="postHoder"
						class="btn btn-primary pa-10"
						:disabled="is_to_merchant_withdrawal"
					><slot name="name-confirm">출금 {{ real_amount }} / {{ withdrawal_balance > shop_info.payment_limit ? shop_info.payment_limit : withdrawal_balance}}</slot></button>
				</div>
				<div
					v-else
					class="mt-auto pa-10 text-center top-line bg-red"
				>잔액이 부족하여 출금 할 수 없습니다.</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'UserWithdrawal'
	, components: {PopupLayer}
	, props: ['user', 'company_fee']
	, data: function(){
		return {

			item_withdrawal: {
				amount: ''
				, bank_code: ''
				, bank_account: ''
				, bank_holder: ''
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, depositor: ''
			}
			, is_holder: false
			, item_holder: {}
			, shop_balance: 0
			, items_account: []
			, item_account: {}
			, account_uid: ''
			, shop_info: {

			}
		}
	}
	,computed: {
		is_to_merchant_withdrawal: function () {
			let t = true
			if (this.item_withdrawal.bank_code && this.item_withdrawal.bank_account && this.item_withdrawal.bank_holder && this.item_withdrawal.amount && this.item_withdrawal.depositor) {
				console.log(Number(this.real_amount) + this.total_fee)
				if (this.shop_balance >= Number(this.real_amount) + this.total_fee) {
					t = false
				}
			}

			return t
		}
		, is_to_merchant_confirm: function () {
			let t = true
			if (this.item_withdrawal.bank_code && this.item_withdrawal.bank_account && this.item_withdrawal.bank_holder && this.item_withdrawal.amount && this.item_withdrawal.depositor && this.item_holder.realBankOwnerName) {
				if (Number(this.shop_balance) >= Number(this.real_amount) + this.total_fee && Number(this.real_amount) <= Number(this.shop_info.payment_limit)) {
					t = false
				}
			}

			return t
		}

		, txt_amount: function(){
			let t = 0
			if(this.item_withdrawal.amount){
				t = this.$common.geKoreanNumber((this.real_amount + '').replaceAll(',', ''))
			}
			return t
		}
		, total_fee: function(){
			let t = 0

			if(Number(this.shop_info.total_fee) > 0){
				t = Number(this.shop_info.total_fee)
			}
			return t
		}
		, withdrawal_balance: function(){
			let t = 0
			if(this.shop_balance > this.total_fee){
				t = this.shop_balance - this.total_fee
			}
			return t
		}
		, real_amount: function(){
			let t = this.item_withdrawal.amount
			t *= Math.pow(10, 0)
			return t
		}
		, is_withdrawal: function(){
			let t = false

			if(this.withdrawal_balance >= this.shop_info.minimum){
				t = true
			}
			return t
		}
		, list_account: function(){
			return this.items_account.filter( (item) => {
				this.user.bank_list.filter( (bank) => {
					if(item.bank_code == bank.bankId){
						item.bank_name = bank.bankName
					}
				})
				return item
			})
		}
	}
	, methods: {

		postWithdrawal: async function(){
			this.$bus.$emit('on', true)

			try {

				const result = await this.$Axios({
					method: 'post'
					, url: 'user/postWithdrawal'
					, data: {
						shop_uid: this.item_withdrawal.shop_uid
						, amount: this.real_amount
						, bank_code: this.item_withdrawal.bank_code
						, bank_account: this.item_withdrawal.bank_account
						, bank_holder: this.item_withdrawal.bank_holder
						, depositor: this.item_withdrawal.depositor
						, holder: this.item_withdrawal.holder
					}
				})

				if (result.success) {
					this.$emit('click')
				} else {
					this.$bus.$emit('notify', {type: 'error', message: result.message})
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				setTimeout( () => {
					this.$bus.$emit('on', false)
				}, 3000)
			}
		}
		, postHoder: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'post'
					, url: 'user/postHolder'
					, data: {
						shop_uid: this.item_withdrawal.shop_uid
						, amount: this.real_amount
						, bank_code: this.item_withdrawal.bank_code
						, bank_account: this.item_withdrawal.bank_account
						, bank_holder: this.item_withdrawal.bank_holder
						, depositor: this.item_withdrawal.depositor
						, holder: this.item_withdrawal.holder
					}
				})
				if(result.success){
					this.is_holder = true
					this.item_holder = result.data
					this.item_withdrawal.holder = this.item_holder.realBankOwnerName
				}else{
					throw result.message
				}
			}catch(e){
				this.is_holder = false
				this.item_holder = {}
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, onPin: function(){
			this.$bus.$off('pinCallback')

			this.$bus.$emit('onPin', {
				type: 'check'
			})

			this.$bus.$on('pinCallback', (call) => {
				console.log('pinCallback', call)
				this.$set(this.item_withdrawal, 'pin', call)
				this.postWithdrawal()
			})
		}
		, cancel: function(){
			if(this.is_holder){
				this.is_holder = false
			}else{
				this.$emit('cancel')
			}
		}
		, getData: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$Axios({
					method: 'get'
					, url: 'user/getShopWithdrawalInfo'
					, data: {
					}
				})
				if(result.success){
					this.shop_balance = result.data.shop_balance
					this.items_account = result.data.account_list
					this.shop_info = result.data.shop_info
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, setAccount: function(){
			this.items_account.filter( (item) => {
				if(item.uid == this.account_uid){
					this.item_withdrawal.bank_code = item.bank_code
					this.item_withdrawal.bank_account = item.bank_account
					this.item_withdrawal.bank_holder = item.bank_holder
				}
			})
		}
	}
	, created() {
		this.getData()
	}
	, watch: {
		real_amount: {
			handler: function(call){
				console.log(call, Number(this.withdrawal_balance), Number(this.shop_info.payment_limit) )
				if(Number(call) > Number(this.withdrawal_balance)){
					this.$set(this.item_withdrawal, 'amount', '')
					this.$bus.$emit('notify', { type: 'error', message: '출금가능금액을 확인하세요'})
				}else if(Number(call) > Number(this.shop_info.payment_limit)){
					this.$set(this.item_withdrawal, 'amount', '')
					this.$bus.$emit('notify', { type: 'error', message: '1회 출금한도를 확인하세요'})
				}
			}
		}
	}
}
</script>